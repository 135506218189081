@import "../../../colors.scss";

#what-we-believe {
    margin: 60px 0px;
}

.what-we-believe-heading,.why-choose-us-heading {
    font-weight: bold;
    font-size: 38px;
    line-height: 64px;
    color: $secondary;
    // margin-top: 14px;
    width: 730px;
}

.what-we-believe-content,.why-choose-us-content {
    font-weight: normal;
    font-size: 18px;
    line-height: 35px;
    margin: 4px 0px;
}
@media (max-width: 992px) {
    .what-we-believe-heading,.why-choose-us-heading {
        font-size: 28px;
    }
    
    .what-we-believe-content,.why-choose-us-content {
        font-size: 15px;
    }
}
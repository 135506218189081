@import "../../colors.scss";
.navbar {
  height: 91px;
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  background: $background-gradient;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 11;
  .nav-item > .nav-link {
    color: $white !important;
    margin: 0px 16px;
    font-size: 19px;
    :hover {
      color: $secondary !important;
    }
  }
}

.logo img {
  width: 123px;
  margin: 0px 16px 0px 0px;
}

.menu_icon {
  height: 30px;
  width: 30px;
  cursor: pointer;
}
li .cross_icon {
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.cross_icon {
  height: 56px;
  text-align: start;
}
@media (max-width: 992px) {
  .navbar-nav,
  .navBtnLink {
    display: block !important;
    position: fixed;
    top: -100%;
    transition: top 0.9s;
    padding: 24px;
    left: 0;
    padding-top: 21px;
    z-index: 10;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 12%);
    width: 100%;
    text-align: center;
    background: $background-gradient;
  }
  .logo svg {
    height: 30px;
  }
}
@media (min-width: 992px) {
  .menu_icon,
  li .cross_icon {
    display: none;
  }
  .navbar {
    // box-shadow: 0 5px 10px rgba(0,0,0,.2);
  }
}
.box-shadow{
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.active {
  top: 0;
  transition: top 0.5s;
  // background: $background-gradient;
}

.navBtnLink {
  padding: 10px 22px;
  background: $secondary;
  border-radius: 4px;
  text-decoration: none;
  outline: none;
  border: none;
  font-size: 22px;
  white-space: nowrap;
  color: $white;
  cursor: pointer;
}

.navBtnLink.home {
  padding: 10px 22px;
  background: $primary;
  border-radius: 4px;
  text-decoration: none;
  outline: none;
  border: none;
  font-size: 22px;
  white-space: nowrap;
  color: $white;
  cursor: pointer;
}

.navBtnLink:hover {
  background: transparent;
  border: 1px solid $secondary;
  color: $secondary !important;
}
.navBtnLink.home:hover {
  background: transparent;
  border: 1px solid $primary;
  color: $primary !important;
}
.navBtnLink svg {
  margin: 0px 16px;
}
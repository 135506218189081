@import "../../../colors.scss";


.hero-heading {
  font-weight: bold;
  font-size: 64px;
  line-height: 98px;
  padding-top: 113px;
  color: $secondary;
}

.hero-subheading {
  font-weight: normal;
  font-size: 30px;
  line-height: 32px;
  margin-top: 8px;
}
.hero-images {
  position: relative;
  margin-top: 30px;
  margin-bottom: 60px;
  .hero-mobile {
    position: absolute;
    height: 493px;
    width: 59%;
    z-index: 2;
    object-fit: contain;
    right: -22%;
    top: 7%;
    filter: drop-shadow(4px 4px 60px rgba(0, 0, 0, 0.4));
  }
  .hero-fruits {
    position: absolute;
    height: 335px;
    width: 23%;
    z-index: 2;
    object-fit: contain;
    left: -7%;
    bottom: -5%;
    top: initial;
  }
  .hSlider {
    img {
      object-fit: cover;
      height: 575px;
      width: 100%;
      margin: 0 auto;
      // border-radius: 10px;
      &:focus-visible {
        outline: transparent !important ;
      }
    }
    &:focus-visible {
      outline: transparent !important ;
    }
  }
}

@media (max-width: 992px) {
  .hero-container {
    overflow: hidden;
  }
  .hero-heading {
    font-size: 38px;
    line-height: 56px;
  }
  .hero-subheading {
    font-size: 22px;
    line-height: 26px;
  }
  .hero-container {
    background: linear-gradient(
      360deg,
      rgba(0, 128, 96, 0.32) 0%,
      rgba(0, 128, 96, 0) 100%
    );
    max-width: 100%;
  }

  .hero-container {
    padding: 0px !important;
  }
  .hero-images {
    .hero-mobile {
      position: absolute;
      height: 112%;
      width: 100%;
      z-index: 2;
      object-fit: contain;
      right: -44%;
      top: -7.5%;
    }
    .hero-fruits {
      position: absolute;
      height: 60%;
      width: 25%;
      z-index: 2;
      object-fit: contain;
      left: -26px;
      bottom: 0;
      top: initial;
    }
    .hSlider {
      img {
        height: 100%;
        width: 90%;
        margin: auto;
      }
    }
  }
}
.sticky {
  position: fixed;
  bottom: 0;
  animation: slide-down 0.7s;
  width: 100%;
}
@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 0.9;
    transform: translateY(0);
  }
}
// .non-sticky {
//   // animation: slide-up 0.7s;
// }
@keyframes slide-up {
  0% {
    opacity: 0.9;
    transform: translateY(-100%);
  }
  100% {
    opacity: 0;
    transform: translateY(0);
  }
}

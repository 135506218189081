@import "../../../colors.scss";

.services-img {
    margin: 0px auto 0px auto;
    // width: 540px;
    width: 100%;
    height: 400px;
    object-fit: contain;
    border-radius: 8px;
  }
  .service-heading {
    color: $secondary;
    font-weight: bold;
    font-size: 30px;
    line-height: 54px;
  }
  .service-subtitle {
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    margin: 22px 0px;
  }
  @media (max-width: 992px) {
    .services-img {
      margin: 13px 0px;
      height: 300px;
      object-fit: contain;
      width: 100%;
    }
    .service-heading {
      margin: 12px 0px;
      font-size: 22px;
      line-height: 32px;
    }
    .service-subtitle {
      margin: 8px 0px;
      font-size: 15px;
      line-height: 26px;
    }
  }
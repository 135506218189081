@import "../../../colors.scss";

#about-us {
    margin: 33px 0px;
}

.about-us-heading {
    font-weight: bold;
    font-size: 38px;
    line-height: 64px;
    color: $secondary;
    margin-top: 14px;
    width: 730px;
}

.about-us-content {
    font-weight: normal;
    font-size: 18px;
    line-height: 35px;
    margin: 4px 0px;
}

@media (max-width: 992px) {
    .about-us-content {
        font-size: 15px;
    }
}
@import "./colors.scss";
$medium: 993px;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: 'Encode Sans Expanded', sans-serif; */
  font-family: 'Fira Sans', sans-serif;
}

.main-container {
  background: $background-gradient;
  color: white;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100%;
  background: $white;
}

a {
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: $secondary !important;
  }
}

.mt-5 {
  margin-top: 5rem;

  @media screen and (max-width: $medium) {
    margin-top: 4.5rem;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.cursor-pointer {
  cursor: pointer;
}

/* Button used to open the chat form - fixed at the bottom of the page */
.chat-icon {
  background-color: #555;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  position: fixed;
  bottom: 23px;
  right: 28px;
  width: max-content;
  z-index: 9;
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }

  50% {
    transform: translate(0, 15px);
  }

  100% {
    transform: translate(0, -0px);
  }
}

.chat-box {

  /* The popup chat - hidden by default */
  .chat-popup {
    border-radius: 0px;
    display: none;
    position: fixed;
    bottom: 0;
    right: 15px;
    border: 3px solid #f1f1f1;
    z-index: 9;
    width: 400px;
  }

  /* Add styles to the form container */
  .form-container {
    max-width: 400px;
    padding: 10px;
    background: #2c507e;
  }

  /* Full-width textarea */
  .form-container textarea {
    width: 100%;
    padding: 15px;
    margin: 5px 0 22px 0;
    border: none;
    background: #f1f1f1;
    resize: none;
    min-height: 200px;
  }

  /* When the textarea gets focus, do something */
  .form-container textarea:focus {
    background-color: #ddd;
    outline: none;
  }

  /* Set a style for the submit/send button */
  .form-container .send {
    background: $background-gradient;
    color: white;
    padding: 16px 20px;
    border: none;
    cursor: pointer;
    width: 100%;
    margin-bottom: 10px;
    opacity: 0.8;
  }

  /* Add a red background color to the cancel button */
  .form-container .cancel {
    background-color: $secondary;
    color: white;
    padding: 16px 20px;
    border: none;
    cursor: pointer;
    width: 100%;
    margin-bottom: 10px;
    opacity: 0.8;
  }

  /* Add some hover effects to buttons */
  .form-container .btn:hover,
  .open-button:hover {
    opacity: 1;
  }
}

@media (max-width: 992px) {
  .chat-box {
    .chat-popup {
      width: 100%;
      right: 0;
    }
  }
}
@import "../../../colors.scss";
$large: 1400px;
$medium: 993px;

#benefits {
  .benefitsSlider {
    height: 650px;
  }

  .sliderContent {
    display: flex !important;
    flex-direction: row;

    @media screen and (max-width: $medium) {
      width: 100%;
      display: inline-block;
      justify-content: center;
      flex-direction: column;
      margin: 0 auto;
      // align-items: center;
      position: relative;
    }
  }

  .bContent {
    margin-top: 32px;
    margin-left: 4.875rem;

    @media screen and (max-width: $medium) {
      margin-left: 0;
    }
  }

  .bTitle {
    font-weight: bold;
    font-size: 40px;
    line-height: 54px;
  }

  .bSubtitle {
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    margin: 32px 0px;
    margin-left: 4.875rem;

    @media screen and (max-width: $medium) {
      width: 100%;
      margin-left: 0;
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .bgTitle {
    max-width: 27.5rem;

    @media screen and (max-width: $medium) {
      max-width: 100%;
    }
  }

  // what-we-do
  .what-we-do-heading,
  .iheading {
    font-weight: bold;
    font-size: 38px;
    line-height: 64px;
    // margin: 16px 0px;
    width: 730px;
    color: $secondary;
  }

  .what-we-do-subheading,
  .isubheading {
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    margin: 16px 0px;
    width: 730px;
  }

  .WhatWeDoImg {
    position: relative;
    &:hover {
      img{
      transition: transform .1s;
      transform: scale(1.02);
      }
    }
    img {
      height: 300px;
      width: 100%;
      object-fit: cover;
    }
    .what-we-do-txt {
      color: white;
      text-align: left;
      transition: transform .3s;
      padding: 0px 32px;
      font-weight: 800;
      font-size: 20px;
      line-height: 48px;
      width: 100%;
      position: relative;
      background: #379c9c;
      min-height: 100px;
      padding: 20px 15px;
      margin-bottom: 15px;
    }
  }

  .what-we-do-slider {
    .content {
      width: 250px;
      margin-right: 24px;
      margin-left: 24px;
    }

    img {
      height: 276px;
      margin: auto;
    }

    .what-we-do-slider-txt {
      font-weight: 500;
      font-style: normal;
      font-size: 18px;
      line-height: 24px;
      margin: 16px 0px 27px 0px;
      text-align: center;
    }
  }

  .what-we-do {
    .slick-slider {
      display: none;
    }
  }

  // InteriorSolutions
  .imobile {
    .mobile_img {
      height: 500px;
      width: 76%;
      object-fit: cover;
      border-radius: 10%;
    }

    .ilist {
      display: flex;
      margin: 24px 0px;

      img {
        width: 72px;
        height: 72px;
        margin: 12px 40px 0px 0px;
      }

      .content {
        margin: 8px 0px;

        .title {
          font-weight: 500;
          font-size: 22px;
          line-height: 32px;
          color: #000;
        }

        .subtitle {
          font-weight: normal;
          font-size: 18px;
          line-height: 26px;
          width: 362px;
        }
      }
    }
  }

  @media (max-width: 992px) {

    // Products, InteriorSolutions
    .what-we-do-heading,
    .iheading {
      width: 100%;
      font-size: 32px;
      line-height: 48px;
      padding-top: 32px;
    }

    .what-we-do-subheading,
    .isubheading {
      width: 100%;
      font-size: 18px;
      line-height: 32px;
    }

    .WhatWeDoImg {
      .what-we-do-txt {
        font-size: 18px;
        line-height: 24px;
        width: 202px;
      }
    }

    .what-we-do {
      background: $primary;
      color: $white;
    }

    .what-we-do {
      .row {
        display: none;
      }

      .slick-slider {
        display: block;
      }
    }

    .imobile {
      .mobile_img {
        width: 100%;
        margin: 20px auto;
        object-fit: contain;
      }

      .ilist {
        img {
          margin: 14px 40px 0px 0px;
        }

        .content .subtitle {
          width: 100%;
          font-size: 15px;
          line-height: 32px;
        }
      }
    }
  }
}
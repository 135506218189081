@import "../../../colors.scss";

.infobox {
  height: 491px;
  color: rgb(255, 255, 255);

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .iheading {
      font-weight: bold;
      font-size: 38px;
      line-height: 64px;
      color: $white;
      margin: 68px 0px 30px 0px;
      color: $secondary;
    }

    .whatsapp-btn {
      background: rgb(69, 90, 100);
      border-radius: 48px;
      color: rgb(255, 255, 255);
      padding: 8px 40px;
      border-style: none;
      margin-bottom: 24px;
    }

    h5 {
      margin-bottom: 24px;
    }

    h6 {
      margin-bottom: 24px;
    }
  }
}

@media (max-width: 992px) {
  .infobox {
    height: 100%;
    .container {
      .iheading {
        font-weight: bold;
        font-size: 28px;
        line-height: 34px;
        color: $white;
        margin: 28px 0px 20px 0px;
      }
  
      .whatsapp-btn {
        background: rgb(69, 90, 100);
        border-radius: 48px;
        color: rgb(255, 255, 255);
        padding: 8px 40px;
        border-style: none;
        margin-bottom: 24px;
        font-size: 15px;
      }
  
      h5 {
        margin-bottom: 24px;
        font-size: 15px;
      }
  
      h6 {
        margin-bottom: 24px;
        font-size: 15px;
      }
    }
  }
}
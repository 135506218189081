
.not-found-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  img{
    width: 200px;
  }
}
@import '../../colors.scss';

.footer {
  .box {
    // height: 314px;
    position: relative;
    z-index: 8;
    background: $primary;

    // background: $secondary;
    .copyright {
      margin-top: 58px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.015em;
      color: rgba(255, 255, 255, 0.48);
      border-top: 1px solid rgba(255, 255, 255, 0.24);
      padding-top: 30px;
      padding-bottom: 20px;
    }

    .row {
      .col {
        display: flex;
        justify-content: space-between;
      }

      .pt-80 {
        padding-top: 150px;
      }

      .contact-info {
        margin-bottom: 24px;
        display: flex;
        justify-content: center;

        span {
          display: inline-block;
          width: 20px;
          text-align: center;
          margin-right: 17px;
        }

        a {
          font-style: normal;
          font-weight: normal;
          font-size: 22px;
          line-height: 30px;
          letter-spacing: -0.015em;
          color: $white;
        }
      }


      .play-store {
        display: flex;
        justify-content: flex-start;

        // margin: 56px 100px 0px 25px;
        .text {
          font-weight: 500;
          font-size: 18px;
          line-height: 28px;
          color: $white;
        }
      }
    }

    .follow {
      grid-column-gap: 15px;
      column-gap: 15px;
      display: flex;
      color: $white;
      margin: 36px 0px 0px 0px;
      font-weight: normal;
      font-size: 22px;
      line-height: 26px;
      align-items: center;
      margin-bottom: 24px;
      justify-content: center;

      .fb_logo {
        width: 60px;
        height: 60px;
        margin: 0px 24px;
      }
    }
  }

  .icons a {
    display: inline-flex;
    width: 100px;
    height: 100px;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 40px;
    margin: 10px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);

    background-size: 100% 200%;
    background-position: 0% 5%;

    transition: background-position 0.5s, color 0.5s;
  }

  .icons a:nth-child(1) {
    color: #1976d3;
    background-image: linear-gradient(#fff 50%, #1976d3 50%);
  }

  .icons a:nth-child(2) {
    color: #db6f44;
    background-image: linear-gradient(#fff 50%, #db6f44 50%);
  }

  .icons a:nth-child(3) {
    color: #0976b4;
    background-image: linear-gradient(#fff 50%, #0976b4 50%);
  }

  .icons a:nth-child(4) {
    color: #03a9f5;
    background-image: linear-gradient(#fff 50%, #03a9f5 50%);
  }

  .icons a:nth-child(5) {
    color: #bb0000;
    background-image: linear-gradient(#fff 50%, #bb0000 50%);
  }

  .icons a:hover {
    background-position: 0% 100%;
    color: #fff;
  }
}

@media (max-width: 992px) {
  .footer {
    .box {
      padding: 50px 24px;

      .contact-info {
        a {
          font-size: 16px !important;
        }
      }

      .row {
        .col {
          display: block;
          justify-content: space-between;
        }

        .pt-80 {
          margin-top: 10px;
          padding-top: 40px;
          border-top: 1px solid rgba(255, 255, 255, 0.24);
        }

        .links {
          flex-wrap: wrap;
          row-gap: 19px;
          flex-direction: column;
        }

        .copyright {
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          color: rgba(255, 255, 255, 0.48);
          margin: 24px 0px;
        }

        .play-store {
          text-align: center;
          margin: 0px;
        }
      }

      .follow {

        // flex-direction: column;
        // flex-wrap: wrap;
        // row-gap: 19px;
        // column-gap: 0px;
        // align-items: flex-start;
        .fb_logo {
          width: 40px;
          height: 40px;
          margin: 0px;
        }
      }
    }

    .icons a {
      width: 30px;
      height: 30px;
      font-size: 20px;
      margin: 5px;
    }
  }
}
@import "../../../colors.scss";


.values-heading {
  font-weight: bold;
  font-size: 38px;
  line-height: 64px;
  color: $secondary;
  margin-top: 14px;
  width: 730px;
}
.values-subheading {
  font-weight: normal;
  font-size: 25px;
  line-height: 64px;
  color: $white;
  width: 730px;
}
.our-values-row {
  margin: 40px 0px 40px 0px;
}
.our-values-title {
  margin: 16px 0px 0px 0px;
  font-weight: 500;
  font-size: 30px;
  line-height: 30px;
  color: $secondary;
}
.our-values-content {
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  margin: 4px 0px;
}
.our-values-icon {
  position: relative;
  &:before {
    background-image: url("/assets/images/common/Line_Indicator.webp");
    content: "";
    height: 4px;
    position: absolute;
    width: calc(100% - 95px);
    // opacity: 0.4;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    // border: 1px dashed $primary;
    border-radius: 10px;
  }
}

.our-values-col:nth-child(1) {
  padding-left: 0px;
}
.our-values-col:nth-child(5) {
  .our-values-icon {
    &::before {
      display: none;
    }
  }
}
.our-values-col .our-values-icon img{
  width: 75px;
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .our-values-col:nth-child(4) {
    .our-values-icon {
      &::before {
        display: none;
      }
    }
  }
  .our-values-col {
    padding: 0px;
  }
}
@media (max-width: 992px) {
  .values-heading {
    width: 100%;
    font-size: 32px;
    line-height: 48px;
    margin-top: 32px;
  }
  .values-subheading{
    font-size: 17px;
    line-height: 29px;
    width: 100%;
  }
  .our-values-row {
    margin: 20px 0px 20px 0px;
  }
  .our-values-col {
    display: flex;
    margin: 32px 0px;
    padding: 0px;
    .our-values-text {
      margin: 0px 16px;
      .our-values-title {
        margin: 0px;
        font-size: 23px !important;
      }
    }
  }
  .our-values-icon {
    &:before {
      content: "";
      position: absolute;
      width: 4px;
      right: 50%;
      top: 71px;
      transform: translateX(-50%);
      height: calc(100% - 16px);
      z-index: 1;
      background-image: url("/assets/images/common/vertical_line.webp");
    }
  }
  .our-values-col:last-child {
    .our-values-icon {
      &::before {
        display: none;
      }
    }
  }
  .our-values-icon img {
    position: relative;
    z-index: 2;
  }
  .our-values-content {
    font-size: 15px;
  }
}
